import React, { useRef, RefCallback } from "react";
import mediumZoom, { Zoom, ZoomOptions } from "medium-zoom";
import { Image, ImageProps, Text } from "@mantine/core";

import "@mantine/core/styles/Image.css";

type ImageZoomProps = ImageProps & {
  options?: ZoomOptions;
};

export function ImageZoom({ options, ...props }: ImageZoomProps) {
  const zoomRef = useRef<Zoom | null>(null);

  function getZoom() {
    if (zoomRef.current === null) {
      zoomRef.current = mediumZoom(options);
    }

    return zoomRef.current;
  }

  const attachZoom: RefCallback<HTMLImageElement> = (node) => {
    const zoom = getZoom();

    if (node) {
      zoom.on("open", (event) => {
        zoomRef.current?.update({
          margin: 50,
          background: "rgba(0,0,0,0.7)",
        });
        // (zoomRef?.current as any)?.style.width = "100%";
      });

      zoom.on("close", (event) => {});
      zoom.attach(node);
    } else {
      zoom.detach();
    }
  };

  return <Image loading="lazy" fit="cover" {...props} ref={attachZoom} />;
}
